var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-container",style:({ 'box-shadow': _vm.boxShadow }),on:{"mouseover":_vm.mouseIn,"mouseleave":_vm.mouseOut}},[_c('router-link',{attrs:{"to":{ name: 'company', params: { company: _vm.company.slug } }}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"logo"},[(_vm.company.primary_logo_large)?_c('img',{attrs:{"src":_vm.company.primary_logo_large}}):_c('font-awesome-icon',{style:({
            color: _vm.company.color
              ? _vm.company.color + ' !important'
              : '#303133 !important',
          }),attrs:{"icon":"building"}})],1),_c('div',{staticClass:"name"},[_c('span',{style:({
            color: _vm.company.color
              ? _vm.company.color + ' !important'
              : '#303133 !important',
          })},[_vm._v(" "+_vm._s(_vm.company.name)+" ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }