<template>
  <div>
    <el-card v-if="error" style="text-align: center">
      We're sorry, but you do not have access to view any companies. If you
      believe this is an error please reach out to your account representative.
    </el-card>
    <div v-else>
      <div class="search-container">
        <el-input v-model="search" prefix-icon="el-icon-search" @input="handleSearch" size="small"
          style="width: 300px; margin-right: 0.5rem;" placeholder="Search By Company Name..." />
        <el-button size="small" icon="el-icon-refresh" @click="refreshPage()" type="warning"></el-button>
      </div>
      <div class="companies-container">
        <company-card v-for="company in companies" :company="company" :key="company.slug"></company-card>
      </div>
      <el-pagination class="pagination" layout="prev, pager, next" @current-change="handleCurrentChange"
        :current-page="currentPage" :page-size="20" :total="totalItems" />
    </div>
  </div>
</template>

<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.companies-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .company-container {
    width: calc(25% - 10px);
    background-color: #fefefe;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: box-shadow 0.2s;

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .logo {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin: 0.5rem;
        position: relative;

        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (1 / 1) * 100%;
        }

        >img,
        svg {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: auto;
          max-height: 180px;
          height: 100%;
          margin: auto;
        }
      }

      .name {
        display: flex;
        justify-content: center;
        font-size: 1.125rem;
        letter-spacing: 0.05rem;
        flex-grow: 1;
        align-items: center;
        background: #fafafa;
        text-align: center;

        span {
          margin: 0.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .companies-container {
    .company-container {
      width: calc(50% - 10px);
    }
  }
}
</style>

<script>
import CompanyCard from "@/partials/Home/CompanyCard"
import { mapGetters, mapActions } from "vuex"
import { debounce } from 'lodash'

export default {
  name: "HomePage",

  components: {
    CompanyCard,
  },

  data() {
    return {
      error: false,
      currentPage: 1,
      search: '',

    }
  },
  computed: {
    ...mapGetters({
      companies: "companies/list",
      totalItems: "companies/totalItems",
    }),
  },

  mounted() {
    this.fetchData(this.currentPage);

    console.log(this.companies);

    if (!this.companies && this.companies.length === 0) {
      this.error = true
    } else if (this.companies.length === 1) {
      this.$router.replace({
        name: "company",
        params: { company: this.companies[0].slug },
      })
    }
  },

  methods: {
    ...mapActions('companies', ['refresh']),

    fetchData(page = 1, search = this.search) {
        this.refresh({ page, query: search });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchData(page);
    },
    handleSearch: debounce(function () {
      this.currentPage = 1;
      this.fetchData(this.currentPage);
    }, 500),
    refreshPage() {
      this.search = '';
      this.currentPage = 1;
      this.fetchData(this.currentPage, this.search);
    },
  }
}
</script>
